import React, { useState, useEffect, useContext } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import { Button, Input, Table, Form, Switch, Modal, Select, Statistic, Row, Col, Card } from 'antd'
import { confirmSWAL, swalAlert } from '../common/utils'
import { EMPLOYEE_ROLES } from '../common/enums'
import { colorsArray } from '../common/colors'

export default function Employees() {
    const { setLoader } = useContext(context)
    const [employees, setEmployees] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [filter, setFilter] = useState({})

    const fetchAllEmployees = async (query = {}) => {
        setLoader(true)
        const res = await Api.getAllEmployees(query)
        setLoader(false)
        if (res?.status) {
            setEmployees(res.data.map(ad => ({ ...ad, id: ad._id })))
        } else {
            swalAlert(res?.message, false)
        }
    }

    useEffect(() => {
        let anal = {
            All: employees.length,
        }
        Object.keys(EMPLOYEE_ROLES).forEach(r => {
            anal[r] = employees.filter(ad => ad.role === r).length
        })
        setAnalytics(anal)
    }, [employees])

    useEffect(() => {
        fetchAllEmployees(filter)
    }, [filter])

    const isActiveChange = async (id, checked) => {
        const data = await Api.updateEmployee({
            id,
            isActive: checked
        })
        if (data?.status) {
            swalAlert('admin updated sucessfully!!')
            setEmployees(employees.map(ad => {
                if (ad.id === id) {
                    return {
                        ...ad,
                        isActive: checked
                    }
                }
                return ad
            }))
        } else {
            swalAlert(data?.message, false)
        }
    }

    const onDelete = async (id) => {
        if (await confirmSWAL('Are you sure?', "Do you want to delete this Admin !!")) {
            const data = await Api.deleteEmployee({ id })
            if (data?.status) {
                swalAlert('Admin deleted sucessfully!!')
                fetchAllEmployees()
            } else {
                swalAlert(data?.message, false)
            }
        }
    }

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        }, {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
        },
        {
            title: "isActive",
            dataIndex: "isActive",
            render: (t, r) => <Switch checked={r.isActive} onChange={(checked) => { isActiveChange(r.id, checked) }} />
        },
        {
            title: "Action",
            render: (t, r) => <Button type='primary' danger onClick={() => onDelete(r.id)}>Delete</Button>
        }
    ]

    const OnModalOk = async () => {
        let err
        if (!modalData.name) {
            err = 'Please enter name'
        } else if (!modalData.mobile) {
            err = 'Please enter mobile number'
        } else if (!modalData.role) {
            err = 'Please select role'
        } else if (isNaN(parseInt(modalData.mobile)) || modalData.mobile.length !== 10) {
            err = 'Please enter valid mobile number'
        }
        if (err) {
            return swalAlert(err, false)
        }
        const data = await Api.createEmployee({
            ...modalData,
            mobile: `91-${modalData.mobile}`
        })
        if (data?.status) {
            swalAlert('Employee added sucessfully!!')
            setModalData({})
            fetchAllEmployees()
            setIsModalVisible(false)
        } else {
            swalAlert(data?.message, false)
        }
    }

    const handleCancel = () => {
        setModalData({})
        setIsModalVisible(false)
    }

    return (<>
        <h2>Employees</h2>
        <br />
        <Row gutter={16}>
            {Object.keys(analytics).map((anal, i) => <Col span={6} key={i}>
                <Card bordered={false} style={{ backgroundColor: colorsArray[i] }} onClick={() => {
                    setFilter(anal === 'All' ? {} : { role: anal })
                }} className="my-2">
                    <Statistic
                        title={anal}
                        value={analytics[anal]}
                        valueStyle={{ color: 'white' }}
                    />
                </Card>
            </Col>)}
        </Row>
        <Button type='primary' onClick={() => {
            setIsModalVisible(true)
            setModalData({
                role: EMPLOYEE_ROLES.ADMIN
            })
        }} className="my-2">Add Employee</Button>
        <Table columns={columns} dataSource={employees} rowKey='id' />
        <Modal title={'Add Employee'}
            open={isModalVisible} onOk={OnModalOk} onCancel={handleCancel}>
            <Form.Item label="Name">
                <Input placeholder='Enter Name' maxLength={50}
                    value={modalData?.name} onChange={e => setModalData({ ...modalData, name: e.target.value })} />
            </Form.Item>
            <Form.Item label="Mobile">
                <Input placeholder='Enter Mobile Number'
                    value={modalData?.mobile} maxLength={10}
                    onChange={e => setModalData({ ...modalData, mobile: e.target.value })} />
            </Form.Item>
            <Form.Item label="Role">
                <Select value={modalData?.role} onChange={e => setModalData({ ...modalData, role: e })}
                    options={[{
                        label: EMPLOYEE_ROLES.ADMIN,
                        value: EMPLOYEE_ROLES.ADMIN
                    },
                    {
                        label: EMPLOYEE_ROLES.MANAGER,
                        value: EMPLOYEE_ROLES.MANAGER
                    }
                    ]
                        || Object.keys(EMPLOYEE_ROLES).map(r => ({
                            label: r,
                            value: r
                        }))} />
            </Form.Item>
        </Modal>
    </>)
}