import { Switch, Button, Table, Row, Col, Card, Statistic } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { context } from '../context'
import { confirmSWAL, swalAlert } from '../common/utils'
import Api from '../common/Api'
import { useNavigate } from 'react-router-dom'
import config from '../config'
import { colorsArray } from '../common/colors'

export default function Blogs() {
    const { setLoader } = useContext(context)
    const navigate = useNavigate()
    const [blogsData, setBlogsData] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [filter, setFilter] = useState({})

    const fetchAllBlogs = async (query = {}) => {
        setLoader(true)
        const res = await Api.searchBlogs({ query })
        setLoader(false)
        if (res?.status) {
            setBlogsData(res.data)
        } else {
            swalAlert(res?.message, false)
        }
    }

    useEffect(() => {
        fetchAllBlogs(filter)
    }, [filter])

    useEffect(() => {
        setAnalytics({
            All: blogsData.length,
            isActive: blogsData.filter(b => b.isActive).length,
            isTrending: blogsData.filter(b => b.isTrending).length,
        })
    }, [blogsData])

    const updateBlog = async (data) => {
        setLoader(true)
        const response = await Api.updateBlog(data)
        setLoader(false)
        if (!response?.status) {
            swalAlert(response?.message, false)
        }
        swalAlert(response?.message, true)
        setBlogsData(blogsData.map((blog) => {
            if (blog._id === data._id) {
                return { ...blog, ...data }
            }
            return blog
        }))
    }

    const columns = [
        {
            title: "Id",
            dataIndex: "_id",
            key: '_id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Date',
            dataIndex: 'updatedAt',
            render: (t, r) => new Date(r.updatedAt).toLocaleDateString()
        },
        {
            title: "Blog",
            dataIndex: "_id",
            render: (t, r) => <Button type='primary' onClick={(e) => {
                e.stopPropagation()
                window.open(`${config.MAIN_WEB_URL}/blogs/${r.title?.split(' ')?.join('-')}`, '_blank').focus()
            }}>View Blog</Button>
        },
        {
            title: "isActive",
            dataIndex: "isActive",
            render: (t, r) => <Switch checked={r.isActive} onChange={(checked, e) => {
                e.stopPropagation()
                updateBlog({ _id: r._id, isActive: checked })
            }} />
        },
        {
            title: "isTrending",
            dataIndex: "isTrending",
            render: (t, r) => <Switch checked={r.isTrending} onChange={(checked, e) => {
                e.stopPropagation()
                updateBlog({ _id: r._id, isTrending: checked })
            }} />
        },
        {
            title: "showBanner",
            dataIndex: "showBanner",
            render: (t, r) => <Switch checked={r?.showBanner} onChange={(checked, e) => {
                e.stopPropagation()
                updateBlog({ _id: r._id, showBanner: checked })
            }} />
        },
        {
            title: "Actions",
            dataIndex: "_id",
            render: (t, r) => <Button danger onClick={async (e) => {
                e.stopPropagation()
                if (!await confirmSWAL('Are you sure you want to delete this blog?')) return
                setLoader(true)
                const res = await Api.deleteBlog(r._id)
                setLoader(false)
                if (res?.status) {
                    setBlogsData(blogsData.filter(b => b._id !== r._id))
                    swalAlert("Blog Deleted Successfully", true)
                } else {
                    swalAlert(res?.message, false)
                }
            }}>Delete</Button>
        }
    ]
    // console.log(blogsData)
    return (
        <>
            <h2>Blogs</h2>
            <br />
            <Row gutter={16}>
                {Object.keys(analytics).map((anal, i) => <Col span={6} key={i}>
                    <Card bordered={false} style={{ backgroundColor: colorsArray[i] }} onClick={() => {
                        setFilter(anal === 'All' ? {} : { [anal]: true })
                    }} className="my-2">
                        <Statistic
                            title={anal}
                            value={analytics[anal]}
                            valueStyle={{ color: 'white' }}
                        />
                    </Card>
                </Col>)}
            </Row>
            <Button type='primary' onClick={() => navigate('/blogs/add')}
                className="my-2">Create Blog</Button>
            <Table columns={columns} dataSource={blogsData} rowKey='id'
                onRow={val => ({ onClick: () => navigate(`/blogs/${val._id}`) })} />
        </>
    )
}